import React from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

interface FadeInOnScrollProps {
  children: React.ReactNode
  /**
   * Optional prop to determine if the animation should only trigger once.
   * If set to true, animations won't re-appear when scrolling back up and back down.
   */
  triggerOnce?: boolean
}

// Duplicated from Zuto UI. Could not use the one from Zuto UI as a wrapper component.
// TODO: We should look at removing the duplication.
const FadeInOnScroll: React.FC<FadeInOnScrollProps> = ({ children, triggerOnce = false }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  useGSAP(() => {
    const element = ref.current
    if (element) {
      gsap.fromTo(
        element,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: element,
            start: 'top bottom-=100',
            toggleActions: triggerOnce ? 'play none none none' : 'play none none reverse',
            onLeave: () => (triggerOnce ? null : gsap.to(element, { opacity: 0, y: 50 })),
            onEnterBack: () =>
              triggerOnce
                ? null
                : gsap.fromTo(element, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 }),
            once: triggerOnce
            //markers: true  // Used for dev only
          }
        }
      )
    }

    return () => {
      if (element) {
        ScrollTrigger.getById(element.id)?.kill()
      }
    }
  }, [ref, triggerOnce])

  return (
    <div ref={ref} style={{ width: '100%', height: '100%', opacity: 0 }}>
      {children}
    </div>
  )
}

export default FadeInOnScroll
