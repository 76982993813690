import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import {
  AutotraderPreferredPartner,
  dFlexCenter,
  ElementContainer,
  isMobileOrTabletDevice,
  RepRatesSmallCopy,
  ThemeOptionsProps,
  themeTextColour,
  tonalPalettes,
  TrustpilotWidgets,
  ZutoButton
} from '@zuto-internal/zuto-ui'
import Image from 'next/image'
import { HeroBannerProps } from '@/types/UI'
import ZHeading from '../Typography/Heading'
import ZBulletList from '../Bullets/BulletList'

const HeroBannerContainer = styled('section')<ThemeOptionsProps>(({
  theme,
  spacingBottomDesktop,
  $themecolour
}) => {
  const isBlueTheme = $themecolour === 'blue'

  return {
    overflow: 'hidden',
    backgroundImage: isBlueTheme
      ? "url('https://cdn.zuto.cloud/assets/images/homepage-new/triangle-right-blue.svg')"
      : "url('https://cdn.zuto.cloud/assets/images/homepage-new/triangle-right-yellow.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    marginTop: theme.spacing(10),
    backgroundSize: '62vw 460px',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '85vw',
      marginTop: 0
    },
    [theme.breakpoints.down('lg')]: {
      backgroundImage: isBlueTheme
        ? "url('https://cdn.zuto.cloud/assets/images/homepage-new/triangle-right-blue-mobile.svg')"
        : "url('https://cdn.zuto.cloud/assets/images/homepage-new/triangle-right-yellow-mobile.svg')",
      marginBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: spacingBottomDesktop
    },
    [theme.breakpoints.up('desktop')]: {
      backgroundSize: '62vw 570px'
    },
    ul: {
      padding: 0,
      marginBottom: theme.spacing(7),
      maxWidth: '32.5rem',
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(12)
      },
      li: {
        marginBottom: theme.spacing(4)
      }
    },
    '& .hero-banner': {
      '&__inner': {
        [theme.breakpoints.up('lg')]: {
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }
      },
      '&__desc': {
        [theme.breakpoints.up('lg')]: {
          padding: `${theme.spacing(12)} ${theme.spacing(7)}`,
          marginTop: theme.spacing(10)
        },
        '& > div:first-of-type': {
          display: 'block',
          marginTop: theme.spacing(4),
          width: '100% !important', // Over-ride Trustpilot widget styling (Mobile only)
          [theme.breakpoints.up('lg')]: {
            display: 'none'
          },
          '& .trustpilot-widget': {
            backgroundColor: 'transparent',
            padding: theme.spacing(2),
            justifyContent: 'flex-start !important',
            '&:first-of-type': {
              '@media (max-width: 488px)': {
                paddingBottom: 0
              }
            },
            '&.header-tp-micro-mobile': {
              '@media (max-width: 488px)': {
                paddingTop: 0
              }
            }
          }
        },
        h1: {
          maxWidth: '315px',
          [theme.breakpoints.between('sm', 'lg')]: {
            maxWidth: 'unset'
          },
          [theme.breakpoints.down('lg')]: {
            marginBottom: theme.spacing(6)
          },
          [theme.breakpoints.up('lg')]: {
            maxWidth: '415px'
          }
        },
        [theme.breakpoints.up('lg')]: {
          width: '100%',
          padding: `${theme.spacing(10)} ${theme.spacing(0)} ${theme.spacing(12)} ${theme.spacing(
            0
          )}`,
          marginTop: 0
        }
      },
      '&__logo-wrapper': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(7)
        },
        '& > img': {
          [theme.breakpoints.down('lg')]: {
            maxWidth: '30%',
            height: 'auto',
            marginLeft: '-32px' // Offset container padding so image sits on the edge of screen
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: '-20px'
          }
        },
        img: {
          '&:first-of-type': {
            minWidth: '42%',
            [theme.breakpoints.up('sm')]: {
              minWidth: '45%'
            },
            [theme.breakpoints.up('lg')]: {
              minWidth: 'unset'
            }
          }
        },
        '& .zuto-car-mobile': {
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        },
        '& .zuto-car-desktop': {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }
      },
      '&__logos': {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(9)
        },
        '.tp-rating': {
          [theme.breakpoints.down('lg')]: {
            display: 'none'
          }
        },
        '& .b-corp': {
          width: '42px',
          height: '71px',
          margin: '0',
          [theme.breakpoints.up('sm')]: {
            width: '55px',
            height: '92px'
          },
          [theme.breakpoints.up('lg')]: {
            margin: `0 ${theme.spacing(13)}`
          }
        },
        '& img:nth-of-type(2)': {
          width: '110px',
          height: '24px',
          [theme.breakpoints.up('sm')]: {
            width: '156px',
            height: '36px',
            margin: `0 ${theme.spacing(4)}`
          }
        }
      },
      '&__cta': {
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(13)
        },
        '& > div:first-of-type': {
          ...dFlexCenter,
          flexDirection: 'column'
        },
        '& .rep-example-small-copy-mobile': {
          marginTop: 0,
          maxWidth: 'unset'
        }
      }
    }
  }
})

const RightSideContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: theme.spacing(12),
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  },
  '.right-side-image': {
    position: 'absolute',
    right: '-110px'
  },
  '.right-side-video': {
    position: 'absolute',
    top: '-5%',
    left: '0px',
    width: '100%',
    height: 'auto',
    outline: 'none',
    clipPath: 'inset(1px 1px)',
    '&:focus': {
      outline: 'none'
    }
  }
}))

const HeroBanner = ({
  spacingBottomDesktop,
  title,
  bulletItems,
  bulletTextColour,
  tickIconColour,
  themeColour = 'blue',
  imgSrc,
  videoSrc,
  buttonUrl,
  buttonContent,
  buttonIdSuffix,
  buttonSubText
}: HeroBannerProps) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isMobileOrTabletDevice(navigator.userAgent))
  }, [])

  const renderCTA = (): JSX.Element => (
    <div className="hero-banner__cta">
      <ZutoButton
        url={buttonUrl}
        content={buttonContent}
        idSuffix={buttonIdSuffix}
        {...{ subText: buttonSubText }}
        spacingBottomDesktop="20px"
      />

      <RepRatesSmallCopy
        colour={tonalPalettes.tertiary[60]}
        className="rep-example-small-copy-mobile"
      />
    </div>
  )

  return (
    <HeroBannerContainer
      spacingBottomDesktop={spacingBottomDesktop}
      id={`hero-banner-${buttonIdSuffix}`}
      className={`banner-${themeColour}-theme`}
      $themecolour={themeColour}
    >
      <ElementContainer className="hero-banner__inner" dFlex>
        <Grid container>
          <Grid item xs={12} lg={8} className="hero-banner__desc">
            <TrustpilotWidgets variant="widget-stars" theme="light" />
            <ZHeading
              variant="displayLarge"
              domElement="h1"
              text={title}
              color={themeTextColour(themeColour)}
            />
            <ZBulletList
              items={bulletItems}
              colour={bulletTextColour}
              tickIconColour={tickIconColour}
              location="header"
            />
            <Box sx={{ maxWidth: '333px', display: { lg: 'block', xs: 'none' } }}>
              {renderCTA()}
            </Box>

            <div className="hero-banner__logo-wrapper">
              <Image
                src="https://cdn.uat.zuto.cloud/assets/images/homepage-new/zuto-car-mobile.png"
                alt="Zuto Branded Car"
                width={200}
                height={130}
                className="zuto-car-mobile"
                priority={true}
              />
              <Image
                src="https://cdn.uat.zuto.cloud/assets/images/homepage-new/zuto-car.png"
                alt="Zuto Branded Car"
                width={520}
                height={166}
                className="zuto-car-desktop"
                priority={true}
              />
              <div className="hero-banner__logos">
                <div className="tp-rating">
                  <TrustpilotWidgets variant="widget-stars" theme="light" />
                </div>
                <Image
                  src="https://cdn.uat.zuto.cloud/assets/images/homepage-new/bcorp.svg"
                  alt="B Corp Logo"
                  width={55}
                  height={92}
                  className="b-corp"
                  priority={true}
                />
                <AutotraderPreferredPartner variant="dark" />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={4} sx={{ display: { lg: 'none', xs: 'block' } }}>
            {renderCTA()}
          </Grid>
          {imgSrc && !isMobile && (
            <Grid item xs={12} lg={4}>
              <RightSideContainer>
                <Image
                  src={imgSrc}
                  className="right-side-img"
                  alt="Car Finance made simple"
                  width={428}
                  height={600}
                />
              </RightSideContainer>
            </Grid>
          )}
          {videoSrc && !isMobile && (
            <Grid item xs={12} lg={4}>
              <RightSideContainer>
                <video className="right-side-video" width="500" autoPlay muted loop>
                  <source src={videoSrc} type="video/mp4" />
                </video>
              </RightSideContainer>
            </Grid>
          )}
        </Grid>
      </ElementContainer>
    </HeroBannerContainer>
  )
}

export default HeroBanner
