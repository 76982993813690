'use client'
import ZHeroBanner2 from '@/components/UI/Banner/HeroBanner2'
import ZTypesOfFinanceContainer from '@/components/UI/FinanceOptions/TypesOfFinanceContainer'
import ZIconTileGridThree from '@/components/UI/Card/IconTileGrid-3'
import FAQs from '@/components/UI/Shared/FAQs/FAQs'
import {
  TypographyColorOptions,
  ZutoButton,
  themeTextColour,
  tonalPalettes
} from '@zuto-internal/zuto-ui'
import { faqAccordionItems, heroBannerProps, howZutoWorksTiles, triangleCardData } from './Content'
import '../../src/components/UI/Homepage/CarFinanceCalculator/CalculatorV2.css'
import { ThemeOptionsProps } from '@zuto-internal/zuto-ui'
import { ThemeButtonWrapper } from '@zuto-internal/zuto-ui'
import FadeInOnScroll from '@/components/Animation/FadeInOnScroll'
import ZHeading from '@/components/UI/Typography/Heading'
import { styled } from '@mui/material'
import ZTriangleStepCards from '@/components/UI/Card/TriangleStepCards'
import { BaseComponentProps } from '@/types/UI'

interface StyledSectionProps {
  headingPadding?: boolean
}

export const StyledSection = styled('section')<StyledSectionProps>(({ theme, headingPadding }) => ({
  h2: {
    ...(headingPadding && {
      padding: `0 ${theme.spacing(6)} 0 ${theme.spacing(6)}`
    })
  }
}))

export const HowZutoWorksStyledSection = styled(StyledSection, {
  shouldForwardProp: (prop) => prop !== '$themecolour'
})<ThemeOptionsProps>(({ theme, $themecolour }) => {
  const isBlueTheme = $themecolour === 'blue'
  return {
    margin: `-1px 0 ${theme.spacing(12)}`,
    padding: `${theme.spacing(8)} 0`,
    backgroundColor: isBlueTheme ? tonalPalettes.primary[10] : tonalPalettes.tertiary[30],
    [theme.breakpoints.up('lg')]: {
      margin: `${theme.spacing(14)} ${theme.spacing(6)}`,
      padding: `${theme.spacing(10)} 0`,
      borderRadius: `${theme.spacing(10)} ${theme.spacing(10)} 0 0`
    }
  }
})

export const FinanceDecisionInMinutesStyledSection = styled(StyledSection)<StyledSectionProps>(
  ({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(12)
    },
    h2: {
      padding: `0 ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
      marginBottom: theme.spacing(8)
    },
    h3: {
      padding: `0 ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
      marginBottom: theme.spacing(12)
    }
  })
)

const HomepageClient = ({ zutoUrl }: BaseComponentProps) => {
  return (
    <>
      <ZHeroBanner2 {...heroBannerProps} />

      <FadeInOnScroll triggerOnce>
        <FinanceDecisionInMinutesStyledSection id="apply-online-steps">
          <ZHeading
            variant="displayLarge"
            text="Get a car finance decision in minutes"
            domElement="h2"
            color={themeTextColour('blue')}
            align="center"
          />
          <ZHeading
            variant="displaySmall"
            text="How car finance works with Zuto"
            domElement="h3"
            color={themeTextColour('blue')}
            align="center"
          />
          <ZTriangleStepCards
            cardData={triangleCardData}
            videoSrc="https://cdn.zuto.cloud/assets/videos/iphone-app-form-video.mp4"
          />
        </FinanceDecisionInMinutesStyledSection>
      </FadeInOnScroll>

      <HowZutoWorksStyledSection id="how-zuto-works" $themecolour="blue">
        <FadeInOnScroll triggerOnce>
          <ZHeading
            text="Why Choose Zuto"
            variant="displayLarge"
            domElement="h2"
            align="center"
            color={tonalPalettes.primary[100] as TypographyColorOptions}
          />
        </FadeInOnScroll>
        <ZIconTileGridThree
          tile1={howZutoWorksTiles.tile1}
          tile2={howZutoWorksTiles.tile2}
          tile3={howZutoWorksTiles.tile3}
          spacingBottomDesktop="40px"
          spacingBottomMobile="40px"
          includeAnimation
        />
        <FadeInOnScroll triggerOnce>
          <ThemeButtonWrapper>
            <ZutoButton
              url={`${zutoUrl}/apply/`}
              content="Get a quote"
              idSuffix="homepage-how-zuto-works"
              spacingBottomDesktop="20px"
              spacingBottomMobile="20px"
              theme="dark"
            />
          </ThemeButtonWrapper>
        </FadeInOnScroll>
      </HowZutoWorksStyledSection>

      <FadeInOnScroll triggerOnce>
        <ZTypesOfFinanceContainer spacingBottomDesktop="60px" spacingBottomMobile="40px" />
        <ThemeButtonWrapper>
          <ZutoButton
            url={`${zutoUrl}/apply/`}
            content="Get a quote"
            idSuffix="homepage-types-of-finance"
            spacingBottomDesktop="60px"
            spacingBottomMobile="40px"
            theme="light"
          />
        </ThemeButtonWrapper>
      </FadeInOnScroll>

      <FadeInOnScroll triggerOnce>
        <StyledSection id="homepage-faqs">
          <FAQs faqAccordionItems={faqAccordionItems} variant="displayLarge" align="left" />
        </StyledSection>
      </FadeInOnScroll>
    </>
  )
}

export default HomepageClient
