import { TextAlign, TypographyVariants } from '@zuto-internal/zuto-ui'
import ZHeading from '../../Typography/Heading'
import { AccordionItem } from '@zuto-internal/zuto-ui'
import { ElementContainer } from '@zuto-internal/zuto-ui'
import ZMuiAccordion from '../../Accordion/MuiAccordion'
import { themeTextColour, ThemeColourOptions } from '@zuto-internal/zuto-ui'

const FAQs = ({
  faqAccordionItems,
  variant,
  align,
  themeColour = 'blue'
}: {
  faqAccordionItems: AccordionItem[]
  variant: TypographyVariants
  align: TextAlign
  themeColour?: ThemeColourOptions
}) => {
  return (
    <>
      <ElementContainer>
        <ZHeading
          variant={variant}
          domElement="h2"
          text="Frequently asked questions"
          align={align}
          color={themeTextColour(themeColour)}
        />
      </ElementContainer>
      <ZMuiAccordion
        items={faqAccordionItems}
        domElement="h3"
        spacingBottomMobile="20px"
        spacingBottomDesktop="40px"
        titleVariant="titleSmall"
      />
    </>
  )
}

export default FAQs
