import { HeroBanner2Props } from '@zuto-internal/zuto-ui'
import { TickIconColorOptions, TypographyColorOptions, tonalPalettes } from '@zuto-internal/zuto-ui'

export const heroBannerProps: HeroBanner2Props = {
  title: 'Car finance made simple',
  bulletItems: [
    { text: 'Rated excellent on Trustpilot' },
    { text: 'Get a finance decision in 60 seconds' },
    { text: 'Browse 1000s of cars from trusted dealers' }
  ],
  bulletTextColour: tonalPalettes.neutral[40] as TypographyColorOptions,
  tickIconColour: 'darkGreen' as TickIconColorOptions,
  buttonUrl: 'https://www.zuto.com/apply/',
  buttonContent: 'Get a quote',
  buttonIdSuffix: 'homepage-herobanner2',
  buttonSubText: 'No impact on your credit score',
  spacingBottomDesktop: '40px',
  spacingBottomMobile: '40px'
}

export const triangleCardData = [
  {
    title: 'Apply Online',
    text: "Use our easy online application to tell us a bit about yourself and how much you'd like to borrow. We'll give you a quick decision and one of our team will be in touch to talk you through your options.",
    buttonUrl: 'https://www.zuto.com/apply/',
    buttonContent: 'Get a quote',
    buttonIdSuffix: 'apply-online'
  },
  {
    title: 'Pick your car',
    text: "Now for the fun part. Whether you've already set your sights on a new car or you want to browse from our network of verified dealers, we'll run a free vehicle and history check on any car you like.",
    buttonUrl: 'https://www.zuto.com/apply/',
    buttonContent: 'Get a quote',
    buttonIdSuffix: 'pick-your-car'
  },
  {
    title: 'Get on the road',
    text: "Once the final checks are completed and you're happy with the details, we'll take care of everything to make buying your new car as easy as possible. You simply need to collect the car and we'll handle the payment with the dealer.",
    buttonUrl: 'https://www.zuto.com/apply/',
    buttonContent: 'Get a quote',
    buttonIdSuffix: 'get-on-the-road'
  }
]

export const howZutoWorksTiles = {
  tile1: {
    icon: 'https://cdn.zuto.cloud/assets/images/icons/pointer-icon.svg',
    iconAlt: 'Pointer Icon',
    headingText: 'Get a decision within minutes',
    bodyText:
      "Once you've filled in our online application, we'll give you a quick decision so you know if we can help you, and how much you'll be able to borrow.",
    textColour: tonalPalettes.primary[100] as TypographyColorOptions
  },
  tile2: {
    icon: 'https://cdn.zuto.cloud/assets/images/icons/file-icon.svg',
    iconAlt: 'File Icon',
    headingText: 'Find the right car finance deal',
    bodyText:
      "You don't need to haggle with a dealer or contact several lenders. At Zuto we'll search our panel of over 17 lenders to find you the best car finance deal for your personal circumstances.",
    textColour: tonalPalettes.primary[100] as TypographyColorOptions
  },
  tile3: {
    icon: 'https://cdn.zuto.cloud/assets/images/icons/car-2-icon.svg',
    iconAlt: 'Car icon',
    headingText: 'Save time and hassle',
    bodyText:
      "We'll be on hand to help with all your car financing needs, from the first quote through to finding the right car.",
    textColour: tonalPalettes.primary[100] as TypographyColorOptions
  }
}

export const faqAccordionItems = [
  {
    title: 'Do I need a deposit to be accepted?',
    content: 'No, a deposit could be beneficial but many lenders may still approve you without one.'
  },
  {
    title: 'Can I get car finance if I have bad credit?',
    content:
      "Yes, we can help people with a less-than-perfect credit history. You may find that you're not offered the great rates that someone with excellent credit history might get, but we may still be able to help you find a finance option to suit your circumstances."
  },
  {
    title: 'What is car finance?',
    content:
      'Car finance is a way for you to buy a new or used car by spreading the cost over a period of months. So, instead of having to pay everything upfront, you can split the cost into manageable amounts that are paid in regular monthly instalments.'
  }
]
