'use client'

import FadeInOnScroll from '@/components/Animation/FadeInOnScroll'
import ZHeading from '@/components/UI/Typography/Heading'
import ZTypesOfFinanceContainer from '@/components/UI/FinanceOptions/TypesOfFinanceContainer'
import ZIconTileGridThree from '@/components/UI/Card/IconTileGrid-3'
import {
  CarFinanceCalculator,
  layoutMaxWidth,
  ThemeButtonWrapper,
  ThemeOptionsProps
} from '@zuto-internal/zuto-ui'
import FAQs from '@/components/UI/Shared/FAQs/FAQs'
import { ZutoButton } from '@zuto-internal/zuto-ui'
import { faqAccordionItems, heroBannerProps, howZutoWorksTiles, triangleCardData } from './Content'
import {
  StyledSection,
  HowZutoWorksStyledSection,
  FinanceDecisionInMinutesStyledSection
} from '@app/homepage-b/HomepageClient'
import { themeTextColour, themeHeadingTextColour } from '@zuto-internal/zuto-ui'
import ZTriangleStepCards from '@/components/UI/Card/TriangleStepCards'
import { BaseComponentProps } from '@/types/UI'
import HeroBanner from '@/components/UI/Banner/HeroBanner'
import { Grid, styled } from '@mui/material'

const CalcWrapper = styled('div')<ThemeOptionsProps>(({ theme }) => ({
  paddingX: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    paddingTop: '140px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  '#react-budget-calculator': {
    padding: theme.spacing(4),
    margin: 0
  }
}))

const HomepageClient = ({ zutoUrl }: BaseComponentProps) => {
  return (
    <>
      <HeroBanner {...heroBannerProps} themeColour="greyAndYellow" />
      <FinanceDecisionInMinutesStyledSection sx={{ ...layoutMaxWidth }} id="apply-online-steps">
        <Grid container>
          <Grid item xs={12}>
            <ZHeading
              variant="displayLarge"
              text="Get a car finance decision in minutes"
              domElement="h2"
              color={themeTextColour('greyAndYellow')}
              align="center"
            />
          </Grid>
          <Grid item xs={12} order={{ xs: 3, lg: 2 }}>
            <ZHeading
              variant="displaySmall"
              text="How car finance works with Zuto"
              domElement="h3"
              color={themeTextColour('greyAndYellow')}
              align="center"
            />
          </Grid>
          <Grid item xs={12} lg={7} order={{ xs: 4, lg: 3 }}>
            <ZTriangleStepCards cardData={triangleCardData} themeColour="greyAndYellow" />
          </Grid>
          <Grid item xs={12} lg={5} order={{ xs: 2, lg: 4 }}>
            <CalcWrapper>
              <CarFinanceCalculator />
            </CalcWrapper>
          </Grid>
        </Grid>
      </FinanceDecisionInMinutesStyledSection>

      <HowZutoWorksStyledSection id="how-zuto-works" $themecolour="greyAndYellow">
        <FadeInOnScroll triggerOnce>
          <ZHeading
            text="Why Choose Zuto"
            variant="displayLarge"
            domElement="h2"
            align="center"
            color={themeHeadingTextColour('greyAndYellow', true)}
          />
        </FadeInOnScroll>
        <ZIconTileGridThree
          tile1={howZutoWorksTiles.tile1}
          tile2={howZutoWorksTiles.tile2}
          tile3={howZutoWorksTiles.tile3}
          spacingBottomDesktop="40px"
          spacingBottomMobile="40px"
          includeAnimation
        />
        <FadeInOnScroll triggerOnce>
          <ThemeButtonWrapper>
            <ZutoButton
              url={`${zutoUrl}/apply/`}
              content="Get a quote"
              idSuffix="homepage-how-zuto-works"
              spacingBottomDesktop="20px"
              spacingBottomMobile="20px"
              theme="dark"
            />
          </ThemeButtonWrapper>
        </FadeInOnScroll>
      </HowZutoWorksStyledSection>

      <FadeInOnScroll triggerOnce>
        <ZTypesOfFinanceContainer
          spacingBottomDesktop="60px"
          spacingBottomMobile="40px"
          includeAnimation
          themeColour="greyAndYellow"
        />
        <ThemeButtonWrapper>
          <ZutoButton
            url={`${zutoUrl}/apply/`}
            content="Get a quote"
            idSuffix="homepage-types-of-finance"
            spacingBottomDesktop="60px"
            spacingBottomMobile="40px"
            theme="light"
          />
        </ThemeButtonWrapper>
      </FadeInOnScroll>

      <FadeInOnScroll triggerOnce>
        <StyledSection id="homepage-faqs">
          <FAQs
            faqAccordionItems={faqAccordionItems}
            variant="displayLarge"
            align="left"
            themeColour="greyAndYellow"
          />
        </StyledSection>
      </FadeInOnScroll>
    </>
  )
}

export default HomepageClient
